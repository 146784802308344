<template>
    <div v-if="page">

      <div class="intro mt-20 dt:mt-40 relative">
        <div class="outer-container">
          <div class="inner-container relative">
            <bleed-container bleed="right">
              <div class="bleed-wrapper relative z-20">
                <div class="copy dt:mb-20 md:pr-5/10">
                  <div class="experience-heading">
                    <h1 class="uppercase">
                      <span class="block">Ex</span>
                      <span class="block pl-4 md:pl-8 2xl:pl-12">per</span>
                      <span class="block pl-12 md:pl-20 2xl:pl-40">ience—</span>
                    </h1>
                  </div>
                  <div class="intro-text-landing pl-12 md:pl-20 2xl:pl-40 mt-12 md:pr-10" v-html="page.elements.intro.value"></div>
                </div>
                <div class="images relative mt-12 md:hidden">
                  <div class="horizontal-image relative z-10">
                    <img :src="page.elements.horizontal_image.value[0].url" alt="">
                  </div>
                  <div class="vertical-image absolute left-0 top-[50px] bottom-[30px] z-20">
                    <img :src="page.elements.vertical_image.value[0].url" alt="" class="absolute left-0 top-0 w-full h-full object-cover">
                  </div>
                </div>
              </div>
            </bleed-container>
          </div>
        </div>



        <div class="images hidden md:block absolute right-0 top-0 left-5/10 bottom-0 dt:left-6/10 dt:-top-20 dt:-bottom-20 2xl:left-5/10">
          <div class="horizontal-image absolute z-10 top-0 bottom-0 left-20 right-0 2xl:left-60">
            <img :src="page.elements.horizontal_image.value[0].url" alt="" class="absolute left-0 top-0 w-full h-full object-cover">
          </div>
          <div class="vertical-image absolute left-0 top-[50px] bottom-[30px] z-20 2xl:left-40">
            <img :src="page.elements.vertical_image.value[0].url" alt="" class="absolute left-0 top-0 w-full h-full object-cover">
          </div>
        </div>
      </div>

      <div class="going-on-container">
          <going-ons :heading="page.elements.going_on_heading.value" :slides="goingOnSlides"  />
      </div>

      <div class="tabs md:border-b md:border-grey-200 mt-40 mb-20">
        <div class="outer-container">
          <div class="inner-container">
            <div class="tab-select md:hidden">
              <select v-model="eventTab" class="w-full bg-black border-b border-white px-0">
                <option value="All">All</option>
                <option value="Upcoming">Upcoming</option>
                <option value="UK & Europe">UK &amp; Europe</option>
                <option value="US & Canada">US &amp; Canada</option>
                <option value="Asia Pacific">Asia Pacific</option>
                <option value="Other">Other</option>
                <option value="Past">Past</option>
              </select>
            </div>
            <div class="tab-items hidden md:flex">
              <button type="button" @click="setTab('All')" :class="{active: eventTab === 'All'}">All</button>
              <button type="button" @click="setTab('Upcoming')" :class="{active: eventTab === 'Upcoming'}">Upcoming</button>
              <button type="button" @click="setTab('UK & Europe')" :class="{active: eventTab === 'UK & Europe'}">UK &amp; Europe</button>
              <button type="button" @click="setTab('US & Canada')" :class="{active: eventTab === 'US & Canada'}">US &amp; Canada</button>
              <button type="button" @click="setTab('Asia Pacific')" :class="{active: eventTab === 'Asia Pacific'}">Asia Pacific</button>
<!--              <button type="button" @click="setTab('Other')" :class="{active: eventTab === 'Other'}">Other</button>-->
              <button type="button" @click="setTab('Past')" :class="{active: eventTab === 'Past'}">Past</button>
            </div>
          </div>
        </div>
      </div>

      <div class="outer-container">
        <div class="inner-container events">
          <masonry
              :cols="{default: 2, 767: 1}"
              :gutter="{default: '30px'}"
          >

            <div class="event-tile relative mt-12" v-for="event in filteredEvents" :key="event.system.id">
              <router-link :to="`/experience/${event.elements.slug.value}`" class="relative z-10 block text-white event-inner bg-grey-100 pl-12 pb-12 lg:pl-20 lg:pb-20">
                <div class="image">
                  <div class="image-container relative">
                    <img :src="`${event.elements.image.value[0].url}?w=500&auto=format`" v-if="event.elements.image.value.length" class="block absolute left-0 top-0 w-full h-full object-cover max-w-none">
                  </div>
                </div>
                <div class="type uppercase mt-4 text-right pr-6 hidden">{{dayjs.utc(event.elements.start_date.value).isSameOrAfter(dayjs(), 'day') ? 'Live event' : 'Past event'}}</div>
                <div class="title mt-8 text-22 md:text-34 font-bold pr-8">{{ event.elements.title.value }}</div>
                <div class="date mt-4 text-16 md:text-22 pr-8">
                  <div v-html="formattedDates(event.elements.start_date, event.elements.end_date).displayDate"></div>
                </div>
                <div class="cta mt-12 text-14 flex flex-row items-center text-grey-500 uppercase">
                  View details
                  <img src="@/assets/icon-arrow.png" alt="" class="w-8 h-4 block ml-4">
                </div>
              </router-link>
            </div>

          </masonry>
        </div>
      </div>
    </div>
</template>

<script>
import Analytics from '@/analytics'
import $ from 'jquery';
import GoingOns from "@/components/GoingOns";
import Kontent from "@/kontent";
import Vue from "vue";
import BleedContainer from "@/components/BleedContainer";
import VueMasonry from 'vue-masonry-css'
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
Vue.use(VueMasonry);
export default {
    name: 'Members',
    metaInfo () {
        if (!this.page) {
            return null
        }

        let meta = [
            {property: 'og:site_name', content: 'CONNECT'},
            {property: 'og:url', content: window.location.href},
            {property: 'og:title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
            {property: 'og:description', content: this.page.elements.page__page_description.value},
            {name: 'title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
            {name: 'keywords', content: this.page.elements.page__meta_keywords.value},
            {name: 'description', content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value},
            {name: 'twitter:card', content: 'summary_large_image'},
            {name: 'twitter:title', content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value},
            {name: 'twitter:description', content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value}
        ]

        // if there's an image
        if (this.page.elements.page__main_image.value.length) {
            meta.push({property: 'og:image', content: this.page.elements.page__main_image.value[0].url})
            meta.push({name: 'twitter:image', content: this.page.elements.page__main_image.value[0].url})
        }

        // if there's a type
        if (this.page.elements.page__page_type.value.length) {
            meta.push({property: 'og:type', content: this.page.elements.page__page_type.value[0].name})
        }

        return {
            title: this.page.elements.page__page_title.value,
            meta: meta
        }
    },
    components: {GoingOns, BleedContainer},
    props: {},
    data() {
        return {
            page: null,
            promo: null,
            goingOnSlides: [],
            showConversations: process.env.VUE_APP_SHOW_CONVERSATIONS,
          events: null,
          events_modular_content: null,
          eventTab: 'All'
        }
    },
    mounted () {
        Analytics.trackPage('Experience')
        if (this.$auth.isAuthenticated) {
            this.$store.dispatch("getFavouriteProducts")
            this.$store.dispatch("getFavouriteWineries")
        }

        const self = this
        Kontent.getItemByType('experience_landing_page').then((res) => {
            this.page = res.data.items[0]
            let g = []
            for (let i = 0; i < this.page.elements.going_on_slides.value.length; i++) {
                g.push(res.data.modular_content[this.page.elements.going_on_slides.value[i]])
                this.goingOnSlides = g
            }
            Vue.nextTick(function () {
                $(self.$el).find('.letter').each(function(i, el){
                    $(el).css({marginRight: Math.random() * 1.2 + 'rem', opacity: 1})
                });
            })

            setTimeout(function(){window.prerenderReady = true}, 1000)
        })
        Kontent.getExperienceEvents().then((res) => {

          let ev = []
          for (let i = 0; i < res.data.items.length; i++) {
            const item = res.data.items[i];
            console.log(item.elements.expiry_date)
            if (dayjs(item.elements.expiry_date.value).isAfter(dayjs()) || !item.elements.expiry_date.value) {
              ev.push(item)
            }
          }

            this.events = ev
          this.events_modular_content = res.data.modular_content
        })


        Kontent.getItemByType('promo').then((res) => {
            this.promo = res.data.items[0]
        })

        $(document).on('scroll', function(){
            if ($(window).scrollTop() > 500) {
                $('body').addClass('scrolled')
            } else {
                $('body').removeClass('scrolled')
            }
        });

        // $('body').on('click', '.mouse', function(e){
        //   e.preventDefault();
        //   console.log('mouseclick')
        //   $('html, body').animate({scrollTop: $(window).height()}, 500)
        // });
    },
    methods: {
      setTab(tab) {
        this.eventTab = tab
      },

    formattedDates(start_date, end_date) {
      const startDateUtc = start_date.value
      const startDateTimezone = start_date.display_timezone
      const endDateUtc = end_date.value
      const endDateTimezone = end_date.display_timezone

      // default as no dates
      let formattedDates = {
        displayDate: "n/a",
        // displayTime: "", // Times not required for event listings HTLP-3347
      }

      // no start date
      if (!startDateUtc) return formattedDates;

      const startDate = startDateUtc && dayjs.utc(startDateUtc).tz(startDateTimezone).format('DD MMMM YYYY')
      const endDate = endDateUtc && dayjs.utc(endDateUtc).tz(endDateTimezone).format('DD MMMM YYYY')

      // no end date, or same dates and times
      if (!endDate || startDateUtc === endDateUtc) {
        formattedDates.displayDate = startDate
        // formattedDates.displayTime = dayjs.utc(startDateUtc).tz(startDateTimezone).format('hh:mma')
      }
      // same dates, but different times
      else if (startDate === endDate) {
        formattedDates.displayDate = startDate
        // formattedDates.displayTime = `${dayjs.utc(startDateUtc).tz(startDateTimezone).format('hh:mma')} - ${dayjs.utc(endDateUtc).tz(endDateTimezone).format('hh:mma')}`
      }
      // different dates
      else {
        // same month and year, different day
        if (dayjs.utc(startDateUtc).tz(startDateTimezone).format('MMMM YYYY') === dayjs.utc(endDateUtc).tz(endDateTimezone).format('MMMM YYYY')) {
          formattedDates.displayDate = `${dayjs.utc(startDateUtc).tz(startDateTimezone).format('DD')} - <span class="whitespace-nowrap">${endDate}</span>`
        }
        // same year, different month
        else if (dayjs.utc(startDateUtc).tz(startDateTimezone).format('YYYY') === dayjs.utc(endDateUtc).tz(endDateTimezone).format('YYYY')) {
          formattedDates.displayDate = `${dayjs.utc(startDateUtc).tz(startDateTimezone).format('DD MMMM')} - <span class="whitespace-nowrap">${endDate}</span>`
        }
        // different month and year
        else {
          formattedDates.displayDate = `${startDate} - <span class="whitespace-nowrap">${endDate}</span>`
        }

        // different dates will always show start and end times
        // formattedDates.displayTime = `${dayjs.utc(startDateUtc).tz(startDateTimezone).format('hh:mma')} - ${dayjs.utc(endDateUtc).tz(endDateTimezone).format('hh:mma')}`
      }

      return formattedDates
    }
    },
  computed: {
    dayjs() {
      return dayjs
    },
      filteredEvents () {
        let filteredEvents = []

        if (!this.events) {
          return []
        }

        for (let i = 0; i < this.events.length; i++) {
          const event = this.events[i];

          if (this.eventTab === 'All') {
            filteredEvents.push(event)
            continue
          }

          if (this.eventTab === 'Upcoming') {
            if (dayjs(event.elements.start_date.value).isAfter(dayjs())) {
              filteredEvents.push(event)
              continue
            }
          }

          if (this.eventTab === 'Past') {
            if (dayjs(event.elements.end_date.value).isBefore(dayjs())) {
              filteredEvents.push(event)
              continue
            }
          }

          if (this.eventTab === 'UK & Europe') {
            if (event.elements.group.value[0].name === 'UK & Europe') {
              filteredEvents.push(event)
              continue
            }
          }

          if (this.eventTab === 'US & Canada') {
            if (event.elements.group.value[0].name === 'US & Canada') {
              filteredEvents.push(event)
              continue
            }
          }

          if (this.eventTab === 'Asia Pacific') {
            if (event.elements.group.value[0].name === 'Asia Pacific') {
              filteredEvents.push(event)
              continue
            }
          }

          if (this.eventTab === 'Other') {
            if (event.elements.group.value[0].name === 'Other') {
              filteredEvents.push(event)
              continue
            }
          }
        }

        return filteredEvents
      }
  }
}
</script>

<style lang="scss">
.events > div > div:nth-child(even) {
  margin-top: 50px;
}
</style>

<style scoped lang="scss">

.vertical-image {
  aspect-ratio: 2/3;
  top: 20%;
  bottom: 15%;
}

.tab-items {
  font-size: 22px;

  button {
    padding: 10px 20px 20px 0;
    position: relative;
    margin-right: 20px;

    &:after {
      display: block;
      content: '';
      position: absolute;
      right: 5px;
      bottom: 0;
      height: 1px;
      transition: all 0.3s;
      transition-delay: 0s;
      background-color: #E8490F;
      opacity: 0;
      width: 50%;
    }

    &.active {
      &:after {
        opacity: 1;
        height: 8px;
      }
    }
  }
}

.events {
  .image-container {
    padding-bottom: 36%;
  }

  .type {
    font-size: 12px;
    color: #9A9A9A;
  }
}

.horizontal-image {
  padding-left: 15%;
}

.promo-banner {
    z-index: 20;

    //@media(min-width: 768px)
    //{
    //  position: sticky;
    //  top: 100px;
    //}
    //
    //@media(min-width: 1024px)
    //{
    //  top: 150px;
    //}

    .small {
        width: 300px;
        @media(min-width: 768px)
        {
            display: none;
        }
    }
    .large {
        width: 728px;
        display: none;
        @media(min-width: 768px)
        {
            display: block;
        }
    }
    .small, .large {
        padding: 10px;
        margin: auto;
        background-color: #000;
    }
    img {
        display: block;
    }
}

.going-on-container, .sponsor-container {
    margin-top: 110px;
    @media(min-width: 768px)
    {
        margin-top: 150px;
    }
}

.intro {
    @media(min-width: 768px)
    {
        display: flex;
        flex-direction: row;
    }
}

h1, .h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 2rem;

    @media(min-width: 1024px)
    {
        font-size: 68px;
    }
}

h2 {
    font-size: 20px;
    font-weight: 600;

    @media(min-width: 768px)
    {
        font-size: 34px;
    }
}
.intro-text {
    color: #9A9A9A;
    margin-top: 20px;

    @media(min-width: 768px)
    {
        margin-top: 0;
        width: 300px;
        margin-left: 40px;
    }
}

.intro-text-landing {
  color: #9A9A9A;
  line-height: 2;
}

.nav-promo {
    max-width: 1050px;
    margin-top: 50px;
}

.nav-promo-item {
    margin-top: 40px;

    .description {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 10;
        width: 40%;
        text-align: left;
        text-shadow: 0 0 2px #000;
        opacity: 0;
        display: none;
        transform: translateX(20px);
        transition: all 0.3s ease;
        background-color: #000;
        padding: 10px;

        @media(min-width: 1025px)
        {
            display: block;
        }
    }

    a {
        display: inline-block;
        position: relative;
        height: 85px;
        width: 100%;

        @media(min-width: 768px)
        {
            height: 171px;
        }

        &:hover {
            //.image {
            //  opacity: 1;
            //}
            .arrow {
                transform: translateX(20px);
            }
            .description {
                opacity: 1;
                transform: none;
            }
        }
    }

    .text {
        font-weight: 600;
        font-size: 18px;
        position: absolute;
        z-index: 2;
        white-space: nowrap;
        text-align: center;
        text-shadow: 0 0 10px rgba(#000, 0.5);
        .letter {
            display: inline-block;
            transition: all 1s ease;
            opacity: 0;
        }

        @media(min-width: 768px)
        {
            font-size: 38px;
        }
    }

    .arrow {
        position: absolute;
        bottom: 0;
        width: 55px;
        z-index: 3;
        transition: transform 0.3s ease, opacity 1s ease;

        @media(min-width: 768px)
        {
            width: 70px;
        }
    }

    .text {
        transition: opacity 1s ease;
    }

    .image {
        position: relative;
        height: 100%;
        z-index: 1;
        transition: opacity 0.3s ease;
        opacity: 0.7;
        overflow: hidden;

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .large-image {
            position: absolute;
            height: 566%;
            img {
                opacity: 0;
                transition: opacity 1s ease;
            }
            @media(min-width: 768px)
            {
                height: 483%;
            }
        }
    }

    &.item1 {
        text-align: right;
        a {
            max-width: 360px;
            @media(min-width: 768px)
            {
                max-width: none;
            }
        }
        .text {
            @media(min-width: 768px)
            {
                left: 20%;
            }
            @media(min-width: 768px)
            {
                left: 35%;
            }
        }
        .image {
            margin-left: 15%;
            .image1 {
                opacity: 1;
            }
            .large-image {
                left: -18%;
                right: 0;
            }
            @media(min-width: 768px)
            {
                margin-left: 40%;
                .large-image {
                    left: -67%;
                }
            }
            @media(min-width: 1024px)
            {
                margin-left: 50%;
                .large-image {
                    left: -100%;
                }
            }
        }
        .arrow {
            left: 6%;
            @media(min-width: 768px)
            {
                left: 35%;
            }
            @media(min-width: 1024px)
            {
                left: 45%;
            }
        }
    }

    &.item2 {
        text-align: left;
        .text {
            right: 10%;
            @media(min-width: 768px)
            {
                right: 15%;
            }
        }
        a {
            max-width: 560px;
            @media(min-width: 768px)
            {
                max-width: none;
            }
        }
        .image {
            .image2 {
                opacity: 1;
            }
            margin-right: 5%;
            .large-image {
                left: 0;
                right: -5%;
                top: -130px;
            }
            @media(min-width: 768px)
            {
                margin-right: 20%;
                .large-image {
                    top: -217px;
                    right: -25%;
                }

            }
        }
        .arrow {
            right: 3%;
            @media(min-width: 768px)
            {
                right: 18%;
            }
        }
        .description {
            text-align: left;
            left: auto;
            right: 20%;
        }
    }

    &.item3 {
        text-align: center;
        @media(min-width: 768px)
        {
            text-align: left;
        }
        .text {
            right: 10%;
            @media(min-width: 768px)
            {
                right: auto;
                left: 20%;
            }
        }
        a {
            max-width: 360px;
            @media(min-width: 768px)
            {
                max-width: none;
            }
        }
        .image {
            .image3 {
                opacity: 1;
            }
            margin-left: 5%;
            margin-right: 35%;
            .large-image {
                left: -11%;
                right: -60%;
                top: auto;
                bottom: 0;
            }
            @media(min-width: 768px)
            {
                margin-left: 10%;
                .large-image {
                    left: -19%;
                    right: -64%;
                }
            }
            @media(min-width: 1024px)
            {
                margin-right: 55%;
                .large-image {
                    left: -29%;
                    right: -157%;
                }
            }
        }
        .arrow {
            right: 33%;
            @media(min-width: 1024px)
            {
                right: 52%;
            }
        }
        .description {
            left: 55%;
        }
    }

    &.item4 {
        text-align: right;
        a {
            max-width: 390px;
            @media(min-width: 768px)
            {
                max-width: none;
            }
        }
        .text {
            right: 20%;
            @media(min-width: 768px)
            {
                right: 5%;
            }
        }
        .image {
            margin-right: 30%;
            .image1 {
                opacity: 1;
            }
            .large-image {
                left: -18%;
                right: 0;
                top: auto;
                bottom: 0;
            }
            @media(min-width: 768px)
            {
                margin-left: 20%;
                margin-right: 10%;
                .large-image {
                    left: -67%;
                }
            }
            @media(min-width: 1024px)
            {
                margin-left: 30%;
                .large-image {
                    left: -100%;
                }
            }
        }
        .arrow {
            right: 27%;
            @media(min-width: 768px)
            {
                right: 8%;
            }
        }
        .description {
            text-align: left;
            left: auto;
            right: 12%;
        }
    }


}

.mouse {
    width: 26px;
    height: 42px;
    border: 2px solid #9A9A9A;
    border-radius: 24px;
    position: fixed;
    bottom: 40px;
    right: 40px;
    // cursor: pointer;
    pointer-events: none;
    z-index:2000;
    opacity:1;
    display: none;
    transition: opacity 1s ease;
    color: #9A9A9A;
    @media(min-width: 1025px)
    {
        display: block;
    }

    .label {
        text-transform: uppercase;
        position: absolute;
        right: 30px;
        top: 7px;
    }
}
.mouse:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 50%;
    margin-left: -2px;
    background-color: #9A9A9A;
    border-radius: 100%;
    width: 4px;
    height: 4px;
    opacity: 1;
    -webkit-transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
    transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
    -webkit-animation: scroll 1.5s -1s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
    animation: scroll 1.5s -1s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
}

@-webkit-keyframes scroll {
    0%, 20% {
        -webkit-transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
        transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
    }
    10% {
        -webkit-transform: translateY(0px) scaleY(1.2) scaleX(1.2) translateZ(0px);
        transform: translateY(0px) scaleY(1.2) scaleX(1.2) translateZ(0px);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(20px) scaleY(2.5) scaleX(0.5) translateZ(0px);
        transform: translateY(20px) scaleY(2.5) scaleX(0.5) translateZ(0px);
        opacity: 0.01;
    }
}
@keyframes scroll {
    0%, 20% {
        -webkit-transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
        transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
    }
    10% {
        -webkit-transform: translateY(0px) scaleY(1.2) scaleX(1.2) translateZ(0px);
        transform: translateY(0px) scaleY(1.2) scaleX(1.2) translateZ(0px);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(20px) scaleY(2.5) scaleX(0.5) translateZ(0px);
        transform: translateY(20px) scaleY(2.5) scaleX(0.5) translateZ(0px);
        opacity: 0.01;
    }
}
</style>

<style lang="scss">
body.scrolled .mouse {
    opacity: 0;
}
</style>
